import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';

import Form, {Input} from '../../../components/Form';
import Button from '../../../components/Button';
import {routes} from '../../../../model/navigation';
import Link from '../../../components/Link';
import {
    passwordValidator,
    passwordConfirmationValidator,
} from '../../../../utils/form-validation';
import {resetPassword} from '../../../../model/authentication';
import {Column, Container, Row, Section} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import formLang from '../../../../lang/forms.lang';
import resetPwdLang from '../../../../lang/pages/passwort-zurücksetzen.lang';

const validationSchema = yup.object({
    password: passwordValidator(true),
    passwordConfirmation: passwordConfirmationValidator,
});

const defaultValues = {
    password: '',
    passwordConfirmation: '',
};

export const SetNewPasswordForm = ({onSubmit, theme}) => {
    const {control, handleSubmit, errors} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    return (
        <Section theme={theme}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Container width="narrow">
                    <Row>
                        <Column xs={12}>
                            <Headline type="h1" align="center">
                                {resetPwdLang.headline}
                            </Headline>
                            <Paragraph align="center">
                                {resetPwdLang.intro}
                            </Paragraph>
                        </Column>
                        <Column md={6}>
                            <Input
                                name={formLang.choosePassword.name}
                                type="password"
                                placeholder={
                                    formLang.choosePassword.placeholder
                                }
                                label={formLang.choosePassword.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column md={6}>
                            <Input
                                name={formLang.passwordConfirm.name}
                                type="password"
                                placeholder={
                                    formLang.passwordConfirm.placeholder
                                }
                                label={formLang.passwordConfirm.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column xs={12} className="ap-padding--top">
                            <Button
                                name="submit"
                                type="submit"
                                label={resetPwdLang.submitButton}
                            />
                        </Column>
                        <Column xs={12} className="ap-padding--top">
                            <Link
                                href={routes.anmelden}
                                icon="arrowForward"
                                className="ap-link--login">
                                {resetPwdLang.loginLink}
                            </Link>
                        </Column>
                    </Row>
                </Container>
            </Form>
        </Section>
    );
};

SetNewPasswordForm.propTypes = {
    onSubmit: PropTypes.func,
    theme: PropTypes.oneOf(sectionThemeNames),
};

SetNewPasswordForm.defaultProps = {
    onSubmit: () => {},
    theme: 'default',
};

const ConnectedSetNewPasswordForm = (props) => {
    const dispatch = useDispatch();
    const handleSubmit = ({password, passwordConfirmation}) => {
        dispatch(resetPassword(password, passwordConfirmation));
    };
    return <SetNewPasswordForm {...props} onSubmit={handleSubmit} />;
};

export default ConnectedSetNewPasswordForm;
