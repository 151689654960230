import React from 'react';

import Layout from '../ui/layout/Layout';
import SetNewPasswordForm from '../ui/domains/authentication/SetNewPasswordForm';

const PasswortZuruecksetzen = () => {
    return (
        <Layout>
            <SetNewPasswordForm theme="blue" />
        </Layout>
    );
};

export default PasswortZuruecksetzen;
