import React from 'react';

export default {
    headline: (
        <>
            <i>
                Passwort <strong>zurücksetzen</strong>
            </i>
        </>
    ),
    intro: (
        <>
            Nun kannst Du Dein neues Passwort festlegen: Trag es einfach im
            linken Feld ein und bestätige es noch einmal im rechten Feld.
        </>
    ),
    submitButton: 'Passwort zurücksetzen',
    loginLink: 'Zum Login',
};
